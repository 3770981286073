<template>
  <div class="col-lg-12">
    <div class="ibox ">
      <div class="ibox-content">
        <b-row>
          <b-col cols="12" sm="6" md="6" lg="3" v-if="this.hasAccess(['users.crud'])">
            <div class="widget lazur-bg p-lg text-center">
              <div class="m-b-md">
                <router-link :to="{ name: 'UsersRead' }">
                  <i class="fa fa-users fa-4x"></i>
                  <h3 class="font-bold mt-3">
                    Gebruikersbeheer
                  </h3>
                </router-link>
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" v-if="this.hasAccess(['crm.companies.crud'])">
            <div class="widget lazur-bg p-lg text-center">
              <div class="m-b-md">
                <router-link :to="{ name: 'CompaniesRead' }">
                  <i class="fa fa-building fa-4x"></i>
                  <h3 class="font-bold mt-3">
                    Bedrijven
                  </h3>
                </router-link>
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" v-if="this.hasAccess(['mailing.emails.crud'])">
            <div class="widget lazur-bg p-lg text-center">
              <div class="m-b-md">
                <router-link :to="{ name: 'EmailsRead' }">
                  <i class="fa fa-envelope fa-4x"></i>
                  <h3 class="font-bold mt-3">
                    Emails
                  </h3>
                </router-link>
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3" v-if="this.hasAccess(['courses.crud'])">
            <div class="widget lazur-bg p-lg text-center">
              <div class="m-b-md">
                <router-link :to="{ name: 'CoursesRead' }">
                  <i class="fa fa-book fa-4x"></i>
                  <h3 class="font-bold mt-3">
                    Opleidingen
                  </h3>
                </router-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import Access from '@/mixins/Access'

export default {
  name: 'Dashboard',
  mixins: [Access]
}
</script>

<style scoped>

</style>
